.panel-img {
  width: 30%;
}

.h-8vh {
  height: 8vh;
}

.design-mode {
  opacity: 0.2;
}

.img-cell {
  width: 5vh;
  height: 5vh;
}
.mt-10vh {
  margin-top: 10vh;
}

.panel-btn {
  width: 10vh;
  padding: 1vh;
  font-size: 3vh;
  overflow: hidden;
}

.display-inline {
  display: inline;
}
