.cell {
  border: solid 1px grey;
  width: 5vh;
  height: 5vh;
  text-align: center;
  position: absolute;
}

.warning {
  opacity: 0.3;
}

.marginleft-0 {
  left: 0vh;
}

.marginleft-1 {
  left: 5vh;
}

.marginleft-2 {
  left: 10vh;
}

.marginleft-3 {
  left: 15vh;
}

.marginleft-4 {
  left: 20vh;
}

.marginleft-5 {
  left: 25vh;
}

.marginleft-6 {
  left: 30vh;
}

.marginleft-7 {
  left: 35vh;
}

.marginleft-8 {
  left: 40vh;
}

.marginleft-9 {
  left: 45vh;
}

.margintop-0 {
  top: 0vh;
}

.margintop-1 {
  top: 5vh;
}

.margintop-2 {
  top: 10vh;
}

.margintop-3 {
  top: 15vh;
}

.margintop-4 {
  top: 20vh;
}

.margintop-5 {
  top: 25vh;
}

.margintop-6 {
  top: 30vh;
}

.margintop-7 {
  top: 35vh;
}

.margintop-8 {
  top: 40vh;
}

.margintop-9 {
  top: 45vh;
}

.cell-img {
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
}
